import api from '@admin/api/service';

export default {
  getHomepagePropertyList(params) {
    return api.get(`/singgahsini/api/property`, { params });
  },

  getCsv(params) {
    return api.get(`/singgahsini/api/property/download`, { params });
  },

  getFilterCity() {
    return api.get('/singgahsini/api/property/city');
  },

  getFilterDistrict(regencyId) {
    return api.get(`/singgahsini/api/area/city/${regencyId}/subdistrict`);
  },

  getFilterProduct() {
    return api.get('/singgahsini/api/property/type');
  },

  getFilterOfficer() {
    return api.get('/singgahsini/api/property/handler');
  },

  getNearbyLocations() {
    return api.get('/singgahsini/api/area/landmark/category');
  },

  getFilterTag(type) {
    return api.get(`/singgahsini/api/tag?type=${type}`);
  }
};
