<template>
  <div class="payment-disbursement-approval-page">
    <div v-if="isLoading" data-testid="disbursement-loadingPage">
      <bg-skeleton width="500px" :height="40" />
    </div>
    <div v-else>
      <div class="payment-disbursement-approval-page__header">
        <bg-text
          size="heading-3"
          class="payment-disbursement-approval-page__header-title mb-24"
        >
          Transfer Pendapatan Pemilik
        </bg-text>

        <payment-disbursement-transfer-card
          :period-label="getPeriodLabel()"
          :total="disbursementAmountTotal"
          :show-cancel="!!canAccessCancelAutoDisburse"
          :disabled-cancel="!isCancelAutoDisbursementEnabled"
          :loading-cancel="isLoadingCancelation"
          class="bg-u-mb-xl"
          data-testid="payment-disbursement-transfer-card"
          @click-cancel="cancelAutoDisbursement"
        />
      </div>

      <payment-disbursement-approval-filter />

      <div v-if="paginationData.total" class="mt-24 mb-16">
        <bg-text size="body-2" class="text-muted">
          Ditemukan {{ paginationData.total }} data transfer
        </bg-text>
      </div>
      <payment-disbursement-approval-table
        v-if="disbursementDataTable.length > 0"
        :table-heads="tableHeads"
      >
        <tr
          v-for="(item, i) in disbursementDataTable"
          :key="`disbursement-data-${i}`"
        >
          <td>{{ item.approval_id }}</td>
          <td>{{ item.property_name }}</td>
          <td>
            <bg-label-rainbow
              :color="statusLabelColor[`${item.status}`]"
              class="payment-disbursement-approval__label"
            >
              {{ approvalTranslation[item.status] }}
            </bg-label-rainbow>
          </td>
          <td>
            <bg-label-rainbow
              class="payment-disbursement-approval__label"
              :color="transferStatusLabelColor[`${item.transfer_status}`]"
            >
              {{ item.transfer_status | transferStatusText | capitalize }}
            </bg-label-rainbow>
          </td>
          <td>{{ item.scheduled_date | date }}</td>
          <td>{{ revenueModelText(item) }}</td>
          <td>{{ item.total_income | rupiah }}</td>
          <td>
            {{ item.account_name }}<br />
            {{ item.account_number }}<br />
            {{ item.account_bank }}<br />
          </td>
          <td>
            {{ item.account_support }}
          </td>
          <td>
            <table-action-menu
              v-if="canAccessApproveDisburse || canAccessDetailDisbursement"
            >
              <bg-list-item
                v-if="
                  approvalText(item.status) &&
                  canAccessApproveDisburse &&
                  !isNoTransferred(item.transfer_status)
                "
                :class="approvalActionClasses(item)"
                :clickable="!item.isApprovalDisabled"
                @click="openDisbursementApprovalModal(item)"
              >
                {{ approvalText(item.status) }}
              </bg-list-item>
              <bg-list-item
                v-if="canAccessDetailDisbursement"
                clickable
                @click="goToDetailTransfer(item)"
              >
                Lihat Detail
              </bg-list-item>
            </table-action-menu>
          </td>
        </tr>
      </payment-disbursement-approval-table>
      <div v-else class="payment-disbursement-approval-page__empty">
        <bg-text size="title-2">Data Tidak Ditemukan</bg-text>
        <bg-text size="body-2"
          >Data tidak ditemukan di filter atau kata kunci yang Anda gunakan
          tidak sesuai.</bg-text
        >
      </div>
      <bg-pagination
        v-if="pageTotal > 1"
        v-model="disbursementPage"
        @click="handlePaginationChange"
        :page-total="pageTotal"
      />
    </div>

    <disbursement-approval-modal
      :is-open.sync="isDisbursementApprovalModalOpen"
      :approval-id.sync="selectedApprovalId"
      :status.sync="selectedStatus"
      @action-success="refreshCurrentTable"
    />

    <approve-and-send-report-modal
      :is-open.sync="isApproveAndSendReportModalOpen"
      :selected-approval-id.sync="selectedApprovalId"
      @action-success="refreshCurrentTable"
    />
  </div>
</template>

<script>
import {
  BgText,
  BgPagination,
  BgSkeleton,
  BgLabelRainbow,
  BgListItem,
} from 'bangul-vue';

import { capitalize } from 'Utils/typography';
import { dateFormatterToDisplay, rupiahFormatter } from 'Utils/formatter';
import { transferStatus } from 'Utils/disbursement';
import EventBus from 'Utils/event-bus';

import { getSessionStorage, removeSessionStorage } from 'Utils/session-storage';

import disbursementApi from '@admin/api/endpoints/disbursement-approval';

import formatter from '@admin/mixins/formatter';

import TableActionMenu from '@admin_molecules/TableActionMenu';
import PaymentDisbursementTransferCard from './components/PaymentDisbursementTransferCard';
import PaymentDisbursementApprovalTable from './components/PaymentDisbursementApprovalTable';
import PaymentDisbursementApprovalFilter from './components/PaymentDisbursementApprovalFilter';

import TRANSFER_DATE_OPTIONS from './constants/transfer-date-options';

export default {
  name: 'PaymentDisbursementApproval',

  components: {
    BgText,
    BgPagination,
    BgSkeleton,
    BgLabelRainbow,
    PaymentDisbursementTransferCard,
    PaymentDisbursementApprovalTable,
    PaymentDisbursementApprovalFilter,
    BgListItem,
    TableActionMenu,
    DisbursementApprovalModal: () =>
      import('@admin_molecules/DisbursementApprovalModal'),
    ApproveAndSendReportModal: () =>
      import(
        '@admin/pages/DetailTransfer/components/ApproveAndSendReportModal'
      ),
  },

  mixins: [formatter],

  filters: {
    capitalize,
    date: value => dateFormatterToDisplay(value, 'DD/MM/YYYY'),
    rupiah: value => {
      if (!value) return '-';
      // Remove the dot from the value
      const clearValue = String(value).replace(/\..+/, '');
      return rupiahFormatter(clearValue);
    },
    transferStatusText: value => {
      if (value) {
        return value.replace(/_/g, ' ');
      }

      return '-';
    },
  },

  data() {
    return {
      disbursementPage: 1,
      isLoading: false,
      disbursementData: {},
      tableHeads: [
        { name: 'ID', sortable: false },
        { name: 'Nama Properti', sortable: false },
        { name: 'Status Data Pendapatan', sortable: false },
        { name: 'Status Transfer', sortable: false },
        { name: 'Jadwal Transfer', sortable: false },
        { name: 'Model Kerja Sama', sortable: false },
        { name: 'Total Transfer Pendapatan', sortable: false },
        { name: 'Detail Rekening', sortable: false },
        { name: 'AS', sortable: false },
        { name: 'Action', sortable: false, align: 'center' },
      ],
      transferStatusLabelColor: {
        waiting: 'yellow',
        processing: 'white',
        failed: 'red',
        transferred: 'green',
        auto_transferred: 'green',
      },
      statusLabelColor: {
        unapproved: 'white',
        approved: 'green',
        approved_by_system: 'green'
      },
      isDisbursementApprovalModalOpen: false,
      selectedApprovalId: null,
      selectedStatus: '',
      isApproveAndSendReportModalOpen: false,
      isLoadingCancelation: false,
    };
  },

  computed: {
    canAccessApproveDisburse() {
      return this.$store.getters.xCheckUserPermission('approve-disburse');
    },
    canAccessDetailDisbursement() {
      return this.$store.getters.xCheckUserPermission('detail-disbursement');
    },
    canAccessCancelAutoDisburse() {
      return this.$store.getters.xCheckUserPermission('cancel-auto-transfer');
    },
    disbursementDataTable() {
      const { PROCESSING, TRANSFERRED } = transferStatus;

      return this.disbursementData.data.properties.map(property => ({
        ...property,
        isApprovalDisabled: [PROCESSING, TRANSFERRED].includes(
          property.transfer_status
        ),
      }));
    },
    paginationData() {
      return this.disbursementData.pagination;
    },
    pageTotal() {
      return Math.ceil(this.paginationData.total / this.paginationData.limit);
    },
    disbursementAmountTotal() {
      return this.disbursementData.data.total_auto_disbursement_amount || 0;
    },
    isCancelAutoDisbursementEnabled() {
      return this.disbursementData.data.is_auto_transfer_cancel_button_enable ||
        false;
    },
    revenueModelText() {
      return item => {
        const texts = [item.revenue_model_name, item.adp, item.jp];
        return texts.filter(text => text).join(' + ');
      };
    },
    approvalText() {
      return stat => {
        switch (stat) {
          case 'unapproved':
            return 'Konfirmasi';

          case 'approved':
            return 'Batalkan Konfirmasi';

          default:
            return '';
        }
      };
    },
    approvalActionClasses() {
      return item => [item.isApprovalDisabled ? 'disabled' : 'primary-color'];
    },
    isNoTransferred() {
      return currentTransferStatus =>
        currentTransferStatus === transferStatus.NO_TRANSFERRED;
    },
    approvalTranslation() {
      return {
        approved: 'Dikonfirmasi',
        unapproved: 'Menunggu Konfirmasi',
        approved_by_system: 'Dikonfirmasi Sistem'
      }
    },
  },

  created() {
    this.handleFetchDisbursementApprovalData({
      offset: 0,
    });

    EventBus.$on('onSubmitFilter', payload => {
      this.disbursementPage = 1;

      this.onFilterChange(this.disbursementPage, payload);
    });

    this.clearStoredFilterData();
  },

  beforeDestroy() {
    this.clearStoredFilterData();
  },

  methods: {
    getStoredFilters() {
      const periodFilters = getSessionStorage('periodFilters') || {};
      const additionalFilters = getSessionStorage('additionalFilters') || {};

      return { periodFilters, additionalFilters };
    },

    getPeriodLabel() {
      const { periodFilters } = this.getStoredFilters();

      const {
        month = null,
        year = null,
        transfer_date: date = 1
      } = periodFilters;

      const monthYearLabel = month && year ? 
        this.mixDateFormatterToDisplayMonthYear(`${year}-${month}`) : 
        this.mixDateFormatterToDisplayMonthYear(Date());

      const dateLabel = TRANSFER_DATE_OPTIONS.find(
        item => item.val === date
      ).shortLabel;

      return `${monthYearLabel} (${dateLabel})`;
    },

    goToDetailTransfer(item) {
      this.$router.push({
        name: 'disbursement.detail-transfer',
        params: {
          approval_id: item.approval_id,
        },
      });
    },

    refreshCurrentTable() {
      this.handlePaginationChange(this.disbursementPage);
    },

    handlePaginationChange(page) {
      const { periodFilters, additionalFilters } = this.getStoredFilters();

      const allFilters = {
        ...periodFilters,
        ...additionalFilters
      };

      this.onFilterChange(page, allFilters);
    },

    onFilterChange(page, params) {
      this.handleFetchDisbursementApprovalData({
        offset: (page - 1) * this.paginationData.limit,
        ...params,
      });
    },

    async handleFetchDisbursementApprovalData(params) {
      try {
        this.isLoading = true;

        const urlParams = this.mixObjectFormatterToURLParams(params);
        const response = await disbursementApi.getDisbursementApprovalTable(
          urlParams
        );

        if (response && response.data) {
          this.disbursementData = response.data;
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.message ||
          'Terjadi kesalahan, silakan coba lagi.'

        this.$toast.show(errorMessage);
      } finally {
        this.isLoading = false;
      }
    },

    openDisbursementApprovalModal(item) {
      this.selectedApprovalId = item.approval_id;

      if (parseFloat(item.total_income) > 0) {
        this.selectedStatus = item.status;
        this.isDisbursementApprovalModalOpen = true;
      } else {
        this.isApproveAndSendReportModalOpen = true;
      }
    },

    async cancelAutoDisbursement() {
      try {
        this.isLoadingCancelation = true;

        const response = await disbursementApi.postCancelAutoDisbursement();

        if (response && !response.error) {
          this.$toast.show('Auto transfer berhasil dibatalkan.')
          this.refreshCurrentTable();
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.error ||
          'Terjadi kesalahan, silakan coba lagi.'

        this.$toast.show(errorMessage);
      } finally {
        this.isLoadingCancelation = false;
      }
    },

    clearStoredFilterData() {
      const storageKeys = ['periodFilters', 'additionalFilters'];

      storageKeys.forEach(key => {
        removeSessionStorage(key);
      });
    },
  },
};
</script>

<style lang="scss" src="./PaymentDisbursementApproval.scss" scoped />
