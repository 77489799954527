export const setSessionStorage = (key, value) => {
  let valueToSet = value;

  if (typeof valueToSet === 'object') {
    valueToSet = JSON.stringify(value);
  }

  sessionStorage.setItem(key, valueToSet);
};

export const getSessionStorage = key => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch {
    return null;
  }
};

export const removeSessionStorage = key => {
  sessionStorage.removeItem(key);
};
