<template>
	<div class="payment-disbursement-approval-table">
		<table class="ss-table">
			<thead>
				<tr>
					<th v-for="(item, i) in tableHeads" :key="`table-heads-${i}`">
						<bg-text size="title-5" :class="textAlign(item.align)">
							{{ item.name }}
							<span v-if="item.sortable">
								<bg-icon
									:name="sortValue === `${item.sortKey}-asc` ? 'up' : 'down'"
									size="sm"
								/>
							</span>
						</bg-text>
					</th>
				</tr>
			</thead>
			<tbody>
				<slot />
			</tbody>
		</table>
	</div>
</template>

<script>
import { BgText, BgIcon } from 'bangul-vue';
import EventBus from 'Utils/event-bus';

export default {
	name: 'PaymentDisbursementApprovalTable',
	components: {
		BgText,
		BgIcon,
	},
	props: {
		tableData: {
			type: Array,
			default: () => [],
		},
		tableHeads: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleSortTable(sortKey) {
			let sortParams = '';
			if (this.sortValue) {
				if (this.sortValue === `${sortKey}-asc`) {
					sortParams = `${sortKey}-desc`;
				} else {
					sortParams = `${sortKey}-asc`;
				}
			} else {
				sortParams = `${sortKey}-asc`;
			}
			const objectParams = {
				...this.retrievedParams,
				sort_by: sortParams,
			};

			sessionStorage.setItem('filterParams', JSON.stringify(objectParams));
			EventBus.$emit('onSubmitFilter', objectParams);
		},
	},
	computed: {
		retrievedParams() {
			const paramsStorage = sessionStorage.getItem('filterParams');
			const retrieved = paramsStorage ? JSON.parse(paramsStorage) : {};
			return retrieved;
		},
		sortValue() {
			return this.retrievedParams.sort_by;
		},
		textAlign() {
			const alignClasses = {
				left: 'ta-l',
				center: 'ta-c',
				right: 'ta-r',
			};
			return (align = 'left') => alignClasses[align] || alignClasses.left;
		},
	},
};
</script>

<style lang="scss" src="./PaymentDisbursementApprovalTable.scss" scoped />
